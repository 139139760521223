<template>
  <v-container id="partners" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + require('../../assets/images/partners/partners-bg.png') + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-secondary--text text-uppercase" style="-webkit-text-stroke: 0.2px white;">{{
              $t("partners")
            }}</h1>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 mt-4">
          <v-col class="text-h5 text-center custom-accent--text text-uppercase">{{$t("thesuccessstories")}}</v-col> 
        </v-row>
        <transition name="fade" mode="out-in" v-if="loading">
          <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="10"
              md="4"
              lg="4"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="partners.length==0 && !loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="partners.length && !loading">
          <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
            <v-col
              v-for="(item, i) in partners.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="10"
              md="4"
              lg="4"
            >
              <v-card
                class="elevation-0 pa-8"
              >
                <v-img
                    :src="require('../../assets/'+item.image)"
                    aspect-ratio="1"
                    max-height="70"
                    contain
                  >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0 mb-4">
          <v-col v-if="Math.ceil(partners.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(partners.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
        <v-row data-aos="fade-down" class="custom-accent white--text pa-0 ma-0" id="contact">
          <v-col cols="12" sm="10" md="7" lg="5" class="text-center ma-auto pa-12">
            <div class="text-h6 text-uppercase">{{$t("interested")}}</div>
            <v-btn class="my-6" @click="$navigateToPath('/contact')" dark depressed rounded>{{$t("contact")}} {{$t("now")}}!<v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "Partners",

  data() {
    return {
      page: 1,
      itemsperpage: 9,
    };
  },
  components: {
  },
  async created() {
    await this.retrievePartners();
  },
  computed: {
    ...mapGetters('partner', {
      partners:'getPartners'
    }),
    ...mapState("partner", {
      loading: "loading"
    }),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    ...mapActions("partner", ["addPartner","retrievePartners"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/partners.css");
</style>